import getFrameworkEnvVar from 'lib/get-framework-env-var';
import { DEFAULT_LOCALE, VALID_LOCALES, getLocaleUrlCode } from '../localization';

export const HOMEPAGE_PATH = '/';
export const WEB_PRODUCT_URL = 'https://my.wealthsimple.com';
export const HELP_SITE_URL_EN = 'https://help.wealthsimple.com/hc/en-ca';
export const HELP_SITE_URL_FR = 'https://help.wealthsimple.com/hc/fr-ca';
export const CONTACT_PATH = '/contact';
export const LEGACY_SIGNUP_URL = 'SIGNUP_PLACEHOLDER';
export const LOCALIZED_LOGIN_URL = 'LOGIN_PLACEHOLDER';
export const TAX_SIGNUP_URL = `${LEGACY_SIGNUP_URL}&product=tax`;
export const TAX_LOGIN_URL = `${LOCALIZED_LOGIN_URL}&redirect=/tax-onboarding`;
export const APP_DOWNLOAD_PATH = '/wealthsimple-app';
export const APP_DOWNLOAD_DEEPLINK = 'https://app.wealthsimple.com/74rd/mobile';
export const APP_DOWNLOAD_QR_DEEPLINK = 'https://app.wealthsimple.com/74rd/webtoapp';
export const APP_DOWNLOAD_DRIP_DEEPLINK = 'https://app.wealthsimple.com/74rd/dividendreinvesting';
export const WEB_SIGNUP = `${WEB_PRODUCT_URL}/app/public/signup`;
export const PREMIUM_PATH = '/premium';
export const PRODUCT_TRADE_PATH = '/self-directed-investing';
export const PRODUCT_CRYPTO_PATH = '/crypto';
export const PRODUCT_INVEST_PATH = '/managed-investing';
export const PRODUCT_OPTIONS_PATH = '/self-directed-investing/options';
export const PRODUCT_TAX_PATH = '/tax';
export const PRODUCT_MORTGAGES_PATH = '/mortgages';
export const PRODUCT_STATUS_PAGE = 'https://status.wealthsimple.com';
export const PRICING_PATH = '/pricing';
export const PRODUCT_SPEND_PATH = '/spend';
export const PRODUCT_PRIVATECREDIT_PATH = '/private-credit';
export const PRODUCT_PRIVATEEQUITY_PATH = '/private-equity';
export const INVEST_PORTFOLIO_CLASSIC = '/managed-investing/classic-portfolio';
export const INVEST_PORTFOLIO_SRI = '/managed-investing/socially-responsible-portfolio';
export const INVEST_PORTFOLIO_HALAL = '/managed-investing/halal-portfolio';
export const ACCOUNTS_PATH = '/accounts';
export const ACCOUNTS_RRSP_PATH = '/accounts/rrsp';
export const ACCOUNTS_TFSA_PATH = '/accounts/tfsa';
export const ACCOUNTS_FHSA_PATH = '/accounts/fhsa';
export const LEARN_PATH = '/learn';
export const MAGAZINE_PATH = '/magazine';
export const HOUSING_GUIDE_PATH = '/buying-a-house';
export const FEE_CALCULATOR_PATH = '/tool/fee-calculator';
export const RETIREMENT_CALCULATOR_PATH = '/tool/retirement-calculator';
export const RRSP_CALCULATOR_PATH = '/tool/rrsp-calculator';
export const TAX_CALCULATOR_PATH_EN = '/tool/tax-calculator';
export const TAX_CALCULATOR_PATH_FR = '/tool/tax-calculator/quebec';
export const TFSA_CALCULATOR_PATH = '/tool/tfsa-calculator';
export const TRANSFERS_PATH = '/get-in-touch';
export const CONTACT_FORM_URL = '/get-in-touch';
export const WORK_URL = '/work';
export const MORTGAGES_PINE_URL = 'https://wealthsimple.pine.ca';
export const CAREERS_LEVER_URL = 'https://jobs.lever.co/wealthsimple';

export const getCLPWebSignupUrl = (shouldSkipProductFork, product) =>
  `${WEB_SIGNUP}${shouldSkipProductFork ? `?product=${product}` : ''}`;

/*
 * Given a path or URL, will append protocol if the protocol is missing
 * (This is needed for Contentful image paths, and relative/local paths)
 *
 * @param {string} A string representing a URL or path
 * @returns {string} The url with the `https` protocol appended if it has a double leading slash
 */
const addProtocolToUrlIfMissing = url => {
  if (!url) return null;

  // relative paths
  if (url.charAt(0) === '/' && url.charAt(1) !== '/') {
    return new URL(url, getFrameworkEnvVar('BASE_URL')).toString();
  }

  // contentful paths
  return url.startsWith('//') ? `https:${url}` : url;
};

/*
 * Given a URL/path and a query parameter string, will append the query parameters to the path
 *
 * @param {string} A string representing a URL or path
 * @param {string} A string representation of query parameters to append to the URL
 * @returns {string} The url/path with the query parameters appended
 */
const appendQueryParameters = (url, queryParameters) => {
  if (!queryParameters || queryParameters?.length === 0) {
    return url;
  }
  const cleanedQueryParameters = queryParameters.indexOf('?') === 0 ? queryParameters.substring(1) : queryParameters;
  return `${url}${url.indexOf('?') === -1 ? '?' : '&'}${cleanedQueryParameters}`;
};

/*
 * Given a path or URL, will strip the trailing slash from the URL if it exists
 *
 * @param {string} A string representing a URL or path
 * @returns {string} The url or path without a trailing slash
 */
const stripTrailingSlash = url =>
  (url.slice(-1) === '/' && url.length > 1 ? url.slice(0, -1) : url);

/*
 * Given a path or URL, returns the absolute Wealthsimple.com URL without a trailing slash,
 * stripping any query parameters or hashes in the URL
 *
 * @param {string} A string representing a URL or path
 * @returns {string} The absolute URL without a trailing slash
 */

const getCanonicalUrl = urlOrPathString => {
  const result = new URL(stripTrailingSlash(urlOrPathString), getFrameworkEnvVar('BASE_URL'));
  return `${result.origin}${result.pathname}`;
};

const getLegacySignupUrl = ({ locale = DEFAULT_LOCALE } = {}) => {
  const localeUrlCode = getLocaleUrlCode(locale);
  return `${WEB_PRODUCT_URL}/app/public/signup?locale=${localeUrlCode}`;
};

const getLoginUrl = ({ locale = DEFAULT_LOCALE } = {}) => {
  const localeUrlCode = getLocaleUrlCode(locale);
  return `${WEB_PRODUCT_URL}/app/login?locale=${localeUrlCode}`;
};

const pathnameHasLocale = url =>
  VALID_LOCALES.some(locale => url.startsWith(`/${getLocaleUrlCode(locale)}`));

const replaceLocaleInPathname = (pathname, locale) => {
  const splitPathname = pathname.split('/');
  if (!pathnameHasLocale(pathname)) {
    splitPathname.splice(1, 0, getLocaleUrlCode(locale));
  } else {
    splitPathname[1] = getLocaleUrlCode(locale);
  }
  return stripTrailingSlash(splitPathname.join('/'));
};

/*
 * Given a full URL on our domain (www.wealthsimple.com, or
 * getFrameworkEnvVar('BASE_URL')), will strip the protocol and domain and
 * return the absolute internal path.
 *
 * Given any other path, will simply return that path.
 *
 * @param {string} A string representing a URL or path
 * @returns {string} A path
 */
const getPathFromLocalUrl = href => {
  const productionUrlPrefix = 'https://www.wealthsimple.com';
  const localUrlPrefix = getFrameworkEnvVar('BASE_URL');
  const replacementPath = '/';
  const buildUrlPrefixRegex = url => {
    return new RegExp(`^${url}([/]*)`);
  };

  if (href.startsWith(productionUrlPrefix)) {
    return href.replace(buildUrlPrefixRegex(productionUrlPrefix), replacementPath);
  }

  if (href.startsWith(localUrlPrefix)) {
    return href.replace(buildUrlPrefixRegex(localUrlPrefix), replacementPath);
  }

  return href;
};

export {
  addProtocolToUrlIfMissing,
  appendQueryParameters,
  getCanonicalUrl,
  stripTrailingSlash,
  getLoginUrl,
  getLegacySignupUrl,
  pathnameHasLocale,
  replaceLocaleInPathname,
  getPathFromLocalUrl,
};
